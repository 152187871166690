<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title slot="start"><ion-label>Academy</ion-label></ion-title>
				
				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="mt-2">

				<ion-row class="buttons-wrapper mt-1">
					<ion-col size="4">
						<ion-button class="button-block" color="danger" :class="{active: searchQuery == 'wezeo'}" @click="searchQuery = searchQuery == 'wezeo' ? '' : 'wezeo' ">Wezeo</ion-button>
					</ion-col>
					<ion-col size="4">
						<ion-button class="button-block" color="danger" :class="{active: searchQuery == 'openlab'}" @click="searchQuery = searchQuery == 'openlab' ? '' : 'openlab' ">Openlab</ion-button>
					</ion-col>
					<ion-col size="4">
						<ion-button class="button-block" color="danger" :class="{active: searchQuery == 'powerplay'}" @click="searchQuery = searchQuery == 'powerplay' ? '' : 'powerplay' ">Powerplay</ion-button>
					</ion-col>
				</ion-row>

				<ion-searchbar placeholder="Vyhľadaj témy" v-model="searchQuery" show-cancel-button="never"></ion-searchbar>

				<ion-segment class="mb-1" :value="activeTab" @ionChange="activeTab = $event.detail.value">
					<ion-segment-button value="all">
						<ion-label>Všetky</ion-label>
					</ion-segment-button>
					
					<ion-segment-button value="style" :disabled="availableYoutubeTags && !availableYoutubeTags.includes('style')">
						<ion-label>Style</ion-label>
					</ion-segment-button>

					<ion-segment-button value="frontend" :disabled="availableYoutubeTags && !availableYoutubeTags.includes('frontend')">
						<ion-label>Frontend</ion-label>
					</ion-segment-button>

					<ion-segment-button value="backend" :disabled="availableYoutubeTags && !availableYoutubeTags.includes('backend')">
						<ion-label>Backend</ion-label>
					</ion-segment-button>
				</ion-segment>

				<h3 class="ion-text-center mt-2 mb-2">Youtube lekcie</h3>
				<ion-row class="mb-2">
					<div class="a-go-wrapper" @click="$router.push('/home')">
						<ion-icon class="ml-1 mr-1" :icon="arrowBackOutline"></ion-icon>
						Späť na Témy
					</div>
				</ion-row>

				<w-loadable class="container" :items="filteredYoutubeThemesByTags" skelet="none" messageEmpty="Aktuáľnemu hľadaniu nevyhovujú žiadne Youtube lekcie">
					<ion-row>
						<ion-col v-for="youtubeTheme in filteredYoutubeThemesByTags" :key="youtubeTheme.id" size="12" size-sm="6" size-md="4">
							<ion-card @click="$router.push({name: 'youtube-theme', params: {themeSlug: youtubeTheme.slug}})">
								<ion-card-header class="ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
									<img v-if="youtubeTheme.image?.path" :src="youtubeTheme.image?.path">
									<img v-else src="./img/youtube-placeholder-img.jpg">
								</ion-card-header>

								<hr>

								<ion-card-title>{{ youtubeTheme.name }}</ion-card-title>
								<ion-chip color="primary" v-for="tag in youtubeTheme.tags" :key="tag">{{ tag }}</ion-chip>
								<ion-text color="primary" class="bottom-button">Začať</ion-text>
							</ion-card>
						</ion-col>
					</ion-row>
				</w-loadable>

			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import { personCircleOutline, logInOutline, arrowBackOutline } from 'ionicons/icons'
import { mapGetters} from 'vuex'

const api = {
	getyoutubeThemes: () => wAxios.get_auth_data('https://open-academy.sk/cms/api/courses?results_per_page=1000'),
}

export default {
	data() {
		return {
			personCircleOutline,
			logInOutline,
			arrowBackOutline,
			filteredYoutubeThemesByTags: null,
			availableYoutubeTags: null,
			youtubeThemes: null,
			activeTab: 'all',
			searchQuery: "",
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		]),
	},

	async created() {
		try {
			this.youtubeThemes = await api.getyoutubeThemes()
			this._filterYoutubeThemes()
		} catch (error) {
			this.youtubeThemes = {error: error}
		}
	},

	methods: {
		search(e) {
			this.searchQuery = e.target.value
		},

		_filterYoutubeThemes() {
			let themes = this.youtubeThemes
			let search = this.searchQuery.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")

			if (this.searchQuery?.length) {
				themes = themes.filter(theme => {
					let tags = []
					if (theme.tags) tags = theme.tags.map(tag => tag.toLowerCase().slice(0, this.searchQuery.length))
					return tags.includes(search) || theme.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "").includes(search)
				})
			} 

			this.availableYoutubeTags = []
			themes.forEach(theme => {if (theme.tags) this.availableYoutubeTags.push(...theme.tags)})

			if (this.activeTab != 'all') {
				themes = themes.filter(theme => {
					let tags = []
					if (theme.tags) tags = theme.tags.map(tag => tag.toLowerCase())
					return tags.includes(this.activeTab)
				})
			}

			this.filteredYoutubeThemesByTags = themes
		},
	},

	watch: {
		searchQuery() {
			this._filterYoutubeThemes()
		},

		activeTab() {
			this._filterYoutubeThemes()
		}
	}
}
</script>

<style lang="sass" scoped>
.buttons-wrapper
	padding: 0
	max-width: 650px
	margin: auto

	ion-button
		opacity: 0.6
		margin: 0
		border-radius: 3px
		box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, .4)
		height: 35px
		&.active
			opacity: 1

ion-row 
	margin-top: 5px
	max-width: 900px
	margin: auto
	
ion-col
	--ion-grid-column-padding: 5px

	ion-card
		padding-bottom: 45px
		height: 100%

		img
			height: 80px

		ion-card-title
			font-size: 14px
			margin-bottom: 5px
			font-weight: 400
			color: var(--ion-color-dark)
</style>